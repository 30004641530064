import * as Sentry from '@sentry/browser';
import { Integrations as TracingIntegrations } from '@sentry/tracing';

const ERROR_SAMPLE_RATE_PRODUCTION = 0.05;
const ERROR_SAMPLE_RATE_DEV = 0;

const TRACE_SAMPLE_RATE = App.isProduction ? 0.005 : 0;

const errorSampleRate = App.isProduction ? ERROR_SAMPLE_RATE_PRODUCTION : ERROR_SAMPLE_RATE_DEV;

let sentrySent = false;
const forceSentry = App.canIForceSentryReporting && /(forceSentry)/.test(window.location.search);
const performanceOptions = App.sentryPerformance
   ? {
        integrations: [new TracingIntegrations.BrowserTracing()],
        tracesSampleRate: TRACE_SAMPLE_RATE,
     }
   : {};

let sampleRate = forceSentry ? 1 : errorSampleRate;
let sampleRateOverride = null;

Sentry.init({
   dsn: App.SENTRY_DSN_JS,
   environment: App.isProduction ? 'production' : 'dev',
   ...performanceOptions,
   sampleRate: 1, // We do custom sampling in beforeSend()
   // Limit to one error per page load please cause we've seen instances of
   // thousands of errors being sent by one user.
   beforeSend: function (event) {
      const thisSampleRate = sampleRateOverride !== null ? sampleRateOverride : sampleRate;

      // Skip if we've already sent an error
      if (sentrySent) {
         return null;
      }

      const shouldSendToSentry = Math.random() < thisSampleRate;
      if (!shouldSendToSentry) {
         return null;
      }

      sentrySent = true;
      return event;
   },
   normalizeDepth: 10,
});

Sentry.setTags(App.sentryTags);
Sentry.setUser({ ip_address: '{{auto}}' });

export function setErrorSampleRate(rate) {
   sampleRateOverride = rate;
}

export function resetErrorSampleRate() {
   sampleRateOverride = null;
}
